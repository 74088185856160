export default {
    "name": "WorkOrderData",
    "kind": "HoudiniQuery",
    "hash": "fa10b5a728a221770aebff5caad2b1694578f2d170230360b0f286b76898c0f9",

    "raw": `query WorkOrderData($pagination: PaginatedInput, $productBatchesFilter: ProductBatchFilter, $groupsFilter: GroupFilter, $analysesFilter: AnalysisFilter, $productsFilter: ProductFilter, $locationsFilter: LocationFilter, $viewAsPlantId: ID!, $userFilter: UserFilter) {
  productBatches(pagination: $pagination, filter: $productBatchesFilter) {
    data {
      id
      name
      product {
        ...WoProductData
        id
      }
      location {
        ...WoLocationData
        id
      }
      plantId
      status
      end
      start
    }
  }
  groups(pagination: $pagination, filter: $groupsFilter) {
    data {
      id
      name
    }
  }
  analyses(filter: $analysesFilter, pagination: $pagination) {
    data {
      ...WoAnalysisData
      sampleTagPrintQuantity(viewAsPlantId: $viewAsPlantId)
      testingTagPrintQuantity(viewAsPlantId: $viewAsPlantId)
      id
    }
  }
  products(filter: $productsFilter, pagination: $pagination) {
    data {
      ...WoProductData
      id
    }
  }
  locations(filter: $locationsFilter, pagination: $pagination) {
    data {
      ...WoLocationData
      id
    }
  }
  userAccounts(filter: $userFilter, pagination: $pagination) {
    data {
      id
      name
    }
  }
}

fragment WoProductData on Product {
  id
  name
  productType
  attachmentCount(fileType: IMAGE)
  __typename
}

fragment WoLocationData on Location {
  id
  location
  description
  plantId
  severityClass {
    id
    default
  }
  attachmentCount(fileType: IMAGE)
  __typename
}

fragment WoAnalysisData on Analysis {
  id
  name
  requireAuthentication
  options {
    ...WoAnalysisOptionData
    id
  }
  __typename
}

fragment WoAnalysisOptionData on AnalysisOption {
  active
  id
  option
  valueType
  thresholdType
  rank
  defaultType
  defaultValue
  unit
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "productBatches": {
                "type": "ProductBatchResponse",
                "keyRaw": "productBatches(filter: $productBatchesFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "ProductBatch",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "product": {
                                        "type": "Product",
                                        "keyRaw": "product",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "productType": {
                                                    "type": "ProductType",
                                                    "keyRaw": "productType",
                                                    "visible": true
                                                },

                                                "attachmentCount": {
                                                    "type": "UnsignedInt",
                                                    "keyRaw": "attachmentCount(fileType: IMAGE)",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "WoProductData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "location": {
                                        "type": "Location",
                                        "keyRaw": "location",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "location": {
                                                    "type": "String",
                                                    "keyRaw": "location",
                                                    "visible": true
                                                },

                                                "description": {
                                                    "type": "String",
                                                    "keyRaw": "description",
                                                    "visible": true
                                                },

                                                "plantId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "plantId",
                                                    "visible": true
                                                },

                                                "severityClass": {
                                                    "type": "SeverityClass",
                                                    "keyRaw": "severityClass",
                                                    "nullable": true,

                                                    "selection": {
                                                        "fields": {
                                                            "id": {
                                                                "type": "PositiveInt",
                                                                "keyRaw": "id",
                                                                "visible": true
                                                            },

                                                            "default": {
                                                                "type": "Boolean",
                                                                "keyRaw": "default",
                                                                "visible": true
                                                            }
                                                        }
                                                    },

                                                    "visible": true
                                                },

                                                "attachmentCount": {
                                                    "type": "UnsignedInt",
                                                    "keyRaw": "attachmentCount(fileType: IMAGE)",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "WoLocationData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ProductBatchStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "end": {
                                        "type": "DateTime",
                                        "keyRaw": "end",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "start": {
                                        "type": "DateTime",
                                        "keyRaw": "start",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "groups": {
                "type": "GroupResponse",
                "keyRaw": "groups(filter: $groupsFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Group",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "analyses": {
                "type": "AnalysisResponse",
                "keyRaw": "analyses(filter: $analysesFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Analysis",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "requireAuthentication": {
                                        "type": "Boolean",
                                        "keyRaw": "requireAuthentication",
                                        "visible": true
                                    },

                                    "options": {
                                        "type": "AnalysisOption",
                                        "keyRaw": "options",

                                        "selection": {
                                            "fields": {
                                                "active": {
                                                    "type": "Boolean",
                                                    "keyRaw": "active",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "option": {
                                                    "type": "String",
                                                    "keyRaw": "option",
                                                    "visible": true
                                                },

                                                "valueType": {
                                                    "type": "ValueType",
                                                    "keyRaw": "valueType",
                                                    "visible": true
                                                },

                                                "thresholdType": {
                                                    "type": "ThresholdType",
                                                    "keyRaw": "thresholdType",
                                                    "visible": true
                                                },

                                                "rank": {
                                                    "type": "NonNegativeInt",
                                                    "keyRaw": "rank",
                                                    "visible": true
                                                },

                                                "defaultType": {
                                                    "type": "DefaultType",
                                                    "keyRaw": "defaultType",
                                                    "visible": true
                                                },

                                                "defaultValue": {
                                                    "type": "String",
                                                    "keyRaw": "defaultValue",
                                                    "nullable": true,
                                                    "visible": true
                                                },

                                                "unit": {
                                                    "type": "String",
                                                    "keyRaw": "unit",
                                                    "visible": true
                                                },

                                                "__typename": {
                                                    "type": "String",
                                                    "keyRaw": "__typename",
                                                    "visible": true
                                                }
                                            },

                                            "fragments": {
                                                "WoAnalysisOptionData": {
                                                    "arguments": {}
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    },

                                    "sampleTagPrintQuantity": {
                                        "type": "UnsignedInt",
                                        "keyRaw": "sampleTagPrintQuantity(viewAsPlantId: $viewAsPlantId)",
                                        "visible": true
                                    },

                                    "testingTagPrintQuantity": {
                                        "type": "UnsignedInt",
                                        "keyRaw": "testingTagPrintQuantity(viewAsPlantId: $viewAsPlantId)",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "WoAnalysisData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "products": {
                "type": "ProductResponse",
                "keyRaw": "products(filter: $productsFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Product",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "productType": {
                                        "type": "ProductType",
                                        "keyRaw": "productType",
                                        "visible": true
                                    },

                                    "attachmentCount": {
                                        "type": "UnsignedInt",
                                        "keyRaw": "attachmentCount(fileType: IMAGE)",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "WoProductData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "locations": {
                "type": "LocationResponse",
                "keyRaw": "locations(filter: $locationsFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Location",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "location": {
                                        "type": "String",
                                        "keyRaw": "location",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "visible": true
                                    },

                                    "severityClass": {
                                        "type": "SeverityClass",
                                        "keyRaw": "severityClass",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "default": {
                                                    "type": "Boolean",
                                                    "keyRaw": "default",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "attachmentCount": {
                                        "type": "UnsignedInt",
                                        "keyRaw": "attachmentCount(fileType: IMAGE)",
                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "WoLocationData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "userAccounts": {
                "type": "UserAccountResponse",
                "keyRaw": "userAccounts(filter: $userFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "UserAccount",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "pagination": "PaginatedInput",
            "productBatchesFilter": "ProductBatchFilter",
            "groupsFilter": "GroupFilter",
            "analysesFilter": "AnalysisFilter",
            "productsFilter": "ProductFilter",
            "locationsFilter": "LocationFilter",
            "viewAsPlantId": "ID",
            "userFilter": "UserFilter"
        },

        "types": {
            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            },

            "ProductBatchFilter": {
                "name": "String",
                "plantIds": "PositiveInt",
                "productIds": "PositiveInt",
                "statuses": "ProductBatchStatus"
            },

            "GroupFilter": {
                "groupIds": "PositiveInt",
                "names": "NonEmptyString",
                "userIds": "PositiveInt",
                "workerGroup": "Boolean"
            },

            "AnalysisFilter": {
                "activeOnly": "Boolean",
                "category": "NonEmptyString",
                "ids": "PositiveInt",
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "type": "AnalysisType"
            },

            "ProductFilter": {
                "active": "Boolean",
                "activeOnly": "Boolean",
                "plantIds": "PositiveInt",
                "plantIdsFilteringByChildren": "PositiveInt",
                "plants": "PositiveInt",
                "productName": "NonEmptyString",
                "productType": "ProductType"
            },

            "ProcessZoneFilter": {
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt"
            },

            "ProductProximityFilter": {
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt"
            },

            "LocationFilter": {
                "activeOnly": "Boolean",
                "location": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "processZone": "ProcessZoneFilter",
                "productProximity": "ProductProximityFilter",
                "testableOnly": "Boolean"
            },

            "UserAuthorizedPlantsFilter": {
                "ids": "PositiveInt",
                "matches": "AnyAllEnum"
            },

            "UserGroupsFilter": {
                "ids": "PositiveInt",
                "matches": "AnyAllEnum"
            },

            "UserFilter": {
                "activeOnly": "Boolean",
                "authorizedPlants": "UserAuthorizedPlantsFilter",
                "groups": "UserGroupsFilter"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=00f5940495721f8f87b02ff081787c4b234a24bd2dde21c6e5b5353c3ced0ff6";