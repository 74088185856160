export default {
    "name": "SetWoChangelogUserSettings",
    "kind": "HoudiniMutation",
    "hash": "c1b343eda85408ae2bf7dd90d058b165d621301372426f5eec999fd42baea4b1",

    "raw": `mutation SetWoChangelogUserSettings($showDeleted: String!, $showUpdated: String!, $showInserted: String!, $showHistorical: String!) {
  setHistorical: setUserSetting(
    value: {category: "Work Orders", name: "Change log: show historical unmodified fields", newValue: $showHistorical, settingType: INTERFACE_HISTORY}
  )
  setDeleted: setUserSetting(
    value: {category: "Work Orders", name: "Change log: show deleted records", newValue: $showDeleted, settingType: INTERFACE_HISTORY}
  )
  setUpdated: setUserSetting(
    value: {category: "Work Orders", name: "Change log: show updated records", newValue: $showUpdated, settingType: INTERFACE_HISTORY}
  )
  setInserted: setUserSetting(
    value: {category: "Work Orders", name: "Change log: show inserted records", newValue: $showInserted, settingType: INTERFACE_HISTORY}
  )
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "setHistorical": {
                "type": "Void",
                "keyRaw": "setHistorical(value: {category: \"Work Orders\", name: \"Change log: show historical unmodified fields\", newValue: $showHistorical, settingType: INTERFACE_HISTORY})",
                "nullable": true,
                "visible": true
            },

            "setDeleted": {
                "type": "Void",
                "keyRaw": "setDeleted(value: {category: \"Work Orders\", name: \"Change log: show deleted records\", newValue: $showDeleted, settingType: INTERFACE_HISTORY})",
                "nullable": true,
                "visible": true
            },

            "setUpdated": {
                "type": "Void",
                "keyRaw": "setUpdated(value: {category: \"Work Orders\", name: \"Change log: show updated records\", newValue: $showUpdated, settingType: INTERFACE_HISTORY})",
                "nullable": true,
                "visible": true
            },

            "setInserted": {
                "type": "Void",
                "keyRaw": "setInserted(value: {category: \"Work Orders\", name: \"Change log: show inserted records\", newValue: $showInserted, settingType: INTERFACE_HISTORY})",
                "nullable": true,
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "showDeleted": "String",
            "showUpdated": "String",
            "showInserted": "String",
            "showHistorical": "String"
        },

        "types": {},
        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=66d440deb94f590794aa04aef0c6aab5c042d0e962bf1207a3cad43ecc3e1169";