<script lang="ts">
	import type { i18n, Mediator } from 'types/common'
	import type { WritableDeep } from 'type-fest'

	import { graphql, type ChangeEventType$options, type WorkOrderChangelog$result, type WorkOrderData$result } from '$houdini'
	import { Table, type Column, Td } from '@isoftdata/svelte-table'
	import { getContext, onDestroy, onMount } from 'svelte'

	import ChangelogDetail from './ChangelogDetail.svelte'
	import Modal from '@isoftdata/svelte-modal'
	import ChangelogFilters from './ChangelogFilters.svelte'

	type ChangelogRow = WritableDeep<WorkOrderChangelog$result['workOrderChangelog'][number]>

	export let workOrderId: number | null
	export let userAccounts: WorkOrderData$result['userAccounts']['data']
	export let mode: 'LIST' | 'TABLE'
	// make these props so we can get default value from settings and persist the value across renders
	export let showHistoricalValues = false
	export let statuses: Array<ChangeEventType$options> = ['INSERT', 'UPDATE', 'DELETE']
	export let userAccountId: number | null = null
	export let dates: { from: string; to: string } | undefined = undefined

	let rows: Array<ChangelogRow> = []
	let selectedGroup: ChangelogRow | null = null
	let isLoading = false
	let changeGroupTable: Table<ChangelogRow> | undefined = undefined
	let showModal = false

	// Hide Connection Info by default on mount / tab change
	$: changeGroupTable?.setColumnVisibility('client', false)

	const mediator = getContext<Mediator>('mediator')
	const { t: translate } = getContext<i18n>('i18next')
	const columns: Array<Column<ChangelogRow>> = [
		{
			name: translate('changelog.operationColumn', 'Operation'),
			title: translate('changelog.operationColumnTitle', 'Type of change that was made'),
			property: 'event',
			width: '1rem',
		},
		{
			name: translate('changelog.recordTypeColumn', 'Record Type'),
			title: translate('changelog.recordTypeColumnTitle', 'Type of record that was changed'),
			property: 'recordType',
		},
		{
			name: translate('changelog.recordNumberColumn', 'Record #'),
			title: translate('changelog.recordNumberColumnTitle', 'ID # of the record that was changed'),
			property: 'rowId',
			numeric: true,
		},
		{
			name: translate('changelog.descriptionColumn', 'Record Desc'),
			title: translate('changelog.descriptionColumnTitle', 'Description of the change'),
			property: 'description',
		},
		{
			name: translate('changelog.userColumn', 'User'),
			title: translate('changelog.userColumnTitle', 'User who made the change'),
			property: 'userAccountId',
		},
		{
			name: translate('changelog.dateColumn', 'Date'),
			title: translate('changelog.dateColumnTitle', 'Date and time of the change'),
			property: 'transactionDate',
			numeric: true,
			defaultSortColumn: true,
			defaultSortDirection: 'DESC',
		},
		{
			name: translate('changelog.connectionInfoColumn', 'Connection Info'),
			title: translate('changelog.connectionInfoColumnTitle', 'Database user and IP address this change was made from'),
			property: 'client',
			numeric: true,
		},
	]

	const badges = {
		INSERT: {
			class: 'success',
			label: translate('common:insert', 'Insert'),
		},
		UPDATE: {
			class: 'primary',
			label: translate('common:update', 'Update'),
		},
		DELETE: {
			class: 'danger',
			label: translate('common:delete', 'Delete'),
		},
	} as const

	const woChangelogQuery = graphql(`
		query WorkOrderChangelog($filter: WorkOrderChangelogFilter!) {
			workOrderChangelog(filter: $filter) {
				rowId
				client
				event
				id
				parentRowId
				table
				recordType
				transactionDate
				userAccountId
				userAccount {
					name
				}
				description
				changes: formattedChanges {
					name
					column
					newValue
					oldValue
					type
				}
			}
		}
	`)

	const updateInterfaceHistoryMutation = graphql(`
		mutation SetWoChangelogUserSettings($showDeleted: String!, $showUpdated: String!, $showInserted: String!, $showHistorical: String!) {
			setHistorical: setUserSetting(value: { category: "Work Orders", name: "Change log: show historical unmodified fields", newValue: $showHistorical, settingType: INTERFACE_HISTORY })
			setDeleted: setUserSetting(value: { category: "Work Orders", name: "Change log: show deleted records", newValue: $showDeleted, settingType: INTERFACE_HISTORY })
			setUpdated: setUserSetting(value: { category: "Work Orders", name: "Change log: show updated records", newValue: $showUpdated, settingType: INTERFACE_HISTORY })
			setInserted: setUserSetting(value: { category: "Work Orders", name: "Change log: show inserted records", newValue: $showInserted, settingType: INTERFACE_HISTORY })
		}
	`)

	async function fetchData() {
		if (workOrderId) {
			try {
				isLoading = true
				const { data } = await woChangelogQuery.fetch({
					variables: {
						filter: {
							workOrderId,
							event: statuses,
							userAccountId,
							dateFrom: dates?.from,
							dateTo: dates?.to,
							showHistoricalValues,
						},
					},
				})
				rows = (data?.workOrderChangelog ?? ([] as Array<ChangelogRow>)).map(group => {
					return {
						...group,
						changes: group.changes.map(change => ({
							...change,
							name: translate(`workOrder.changelog.columns.${change.column}`, change.name),
						})),
					}
				})
				if (selectedGroup) {
					selectedGroup = rows.find(row => row.id === selectedGroup?.id) ?? null
				}
				isLoading = false
			} catch (err: any) {
				console.error('Error fetching work order changelog', err)
				isLoading = false
				mediator.call('showMessage', {
					heading: translate('changelog.errorLoadingDataHeader', 'Error Loading Changelog'),
					message: err.message,
					type: 'danger',
					time: false,
				})
			}
		}
	}

	onMount(fetchData)

	onDestroy(() => {
		updateInterfaceHistoryMutation.mutate({
			showDeleted: statuses.includes('DELETE') ? 'True' : 'False',
			showHistorical: showHistoricalValues ? 'True' : 'False',
			showInserted: statuses.includes('INSERT') ? 'True' : 'False',
			showUpdated: statuses.includes('UPDATE') ? 'True' : 'False',
		})
	})
</script>

<div class="form-row">
	<div
		class="col-12 align-self-start"
		class:col-lg-8={!!selectedGroup && mode === 'TABLE'}
	>
		{#if mode === 'TABLE'}
			<Table
				columnHidingEnabled
				responsive
				stickyHeader
				filterEnabled
				idProp="id"
				perPageCount={15}
				{rows}
				{columns}
				filterPlaceholder={translate('changelog.filterPlaceholder', 'Filter (including values)')}
				bind:this={changeGroupTable}
				rowMatchesFilterMethod={(filter, row, props) =>
					changeGroupTable?.defaultRowMatchesFilter(filter, row, props) ||
					row.changes.some(change =>
						['name', 'newValue', 'oldValue'].some(prop => {
							return change[prop]?.toLowerCase().includes(filter.toLowerCase())
						}),
					)}
				let:row
			>
				<svelte:fragment slot="header">
					<ChangelogFilters
						bind:dates
						bind:isLoading
						bind:statuses
						bind:userAccountId
						{userAccounts}
						{fetchData}
					></ChangelogFilters>
				</svelte:fragment>
				<tr
					class="text-nowrap"
					class:table-primary={selectedGroup?.id === row.id}
					on:click={() => (selectedGroup = row === selectedGroup ? null : row)}
				>
					<Td property="event"><span class="badge badge-{badges[row.event].class} w-100">{badges[row.event].label}</span></Td>
					<Td property="recordType">{row.recordType}</Td>
					<Td property="rowId">{row.rowId}</Td>
					<Td property="description">{row.description}</Td>
					<Td property="userAccountId">{row.userAccount?.name ?? ''}</Td>
					<Td property="transactionDate">{new Date(row.transactionDate).toLocaleString()}</Td>
					<Td property="client">{row.client}</Td>
				</tr>

				<svelte:fragment slot="no-rows">
					<tr>
						<td
							class="text-center"
							colspan={columns.length}>{translate('changelog.noChanges', 'No changes found for the selected filter')}</td
						>
					</tr>
				</svelte:fragment>
			</Table>
		{:else}
			<div class="filter-padding">
				<ChangelogFilters
					bind:dates
					bind:isLoading
					bind:statuses
					bind:userAccountId
					{userAccounts}
					{fetchData}
				></ChangelogFilters>
			</div>
			<ul class="list-group list-group-flush list-group-striped mh-60vh overflow-y-auto border-top">
				{#each rows as group}
					<button
						class="list-group-item list-group-item-action d-flex flex-wrap flex-sm-nowrap"
						on:click={() => {
							selectedGroup = group
							showModal = true
						}}
					>
						<div class="text-left align-self-start flex-grow-1">
							<strong>{translate('changelog.recordTypeAndNumber', '{{recordType}} #{{recordNumber}}', { recordType: group.recordType, recordNumber: group.rowId })}</strong>
							<div>{group.description}</div>
							{#if group.event === 'UPDATE'}
								<div>
									{translate('changelog.changeCount', '{{count}} changes', {
										count: showHistoricalValues || isLoading ? group.changes.filter(c => c.oldValue !== c.newValue).length : group.changes.length,
									})}
								</div>
							{/if}
						</div>
						<div class="text-sm-right w-xs-100">
							<strong class="text-{badges[group.event].class}">{badges[group.event].label}</strong>
							<div>{group.userAccount?.name ?? ''}</div>
							<div>{new Date(group.transactionDate).toLocaleString()}</div>
						</div>
					</button>
				{/each}
			</ul>
		{/if}
	</div>
	{#if selectedGroup && mode === 'TABLE'}
		<div class="col-12 col-lg-4 align-self-start">
			<ChangelogDetail
				{selectedGroup}
				bind:showHistoricalValues
				on:showHistoricalValuesChange={fetchData}
			></ChangelogDetail>
		</div>
	{/if}
</div>

<Modal
	bind:show={showModal}
	title={translate('changelog.modalTitle', 'Changes for {{recordType}} #{{recordNumber}}', { recordType: selectedGroup?.recordType, recordNumber: selectedGroup?.rowId })}
	confirmShown={false}
	on:close={() => (showModal = false)}
>
	{#if selectedGroup}
		<ChangelogDetail
			{selectedGroup}
			bind:showHistoricalValues
			on:showHistoricalValuesChange={fetchData}
		></ChangelogDetail>
	{/if}
</Modal>

<style>
	/* Match card body / filter padding from table view */
	.filter-padding {
		padding-left: 1.25rem;
		padding-right: 1.25rem;
		padding-top: 1.25rem;
	}

	@media (max-width: 577px) {
		.w-xs-100 {
			width: 100% !important;
		}
	}

	ul.list-group-striped button:nth-of-type(odd):not(.list-group-item-primary) {
		background-color: rgba(0, 0, 0, 0.05);
	}
</style>
