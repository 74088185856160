export default {
    "name": "ProductBatchesBatches",
    "kind": "HoudiniQuery",
    "hash": "bd7d9af705e334462dcec8e8509424d593e7331af45c7a2fb8a1efa3d519e7c8",

    "raw": `query ProductBatchesBatches($filter: ProductBatchFilter) {
  productBatches(filter: $filter) {
    data {
      id
      name
      description
      location {
        id
        location
      }
      start
      end
      status
      productId
      plantId
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "productBatches": {
                "type": "ProductBatchResponse",
                "keyRaw": "productBatches(filter: $filter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "ProductBatch",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "location": {
                                        "type": "Location",
                                        "keyRaw": "location",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "location": {
                                                    "type": "String",
                                                    "keyRaw": "location",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "start": {
                                        "type": "DateTime",
                                        "keyRaw": "start",
                                        "visible": true
                                    },

                                    "end": {
                                        "type": "DateTime",
                                        "keyRaw": "end",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "status": {
                                        "type": "ProductBatchStatus",
                                        "keyRaw": "status",
                                        "visible": true
                                    },

                                    "productId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "productId",
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "filter": "ProductBatchFilter"
        },

        "types": {
            "ProductBatchFilter": {
                "name": "String",
                "plantIds": "PositiveInt",
                "productIds": "PositiveInt",
                "statuses": "ProductBatchStatus"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=1598e693fcf04a7b545b81b5dab98bb045961582da232e2801507be85fe2361a";