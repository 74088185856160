export default {
    "name": "LoadUserGroups",
    "kind": "HoudiniQuery",
    "hash": "34c65c5fdd194b72a01f1acfeeaa3da75afbbd2f9e1b76077effcd28c32cef19",

    "raw": `query LoadUserGroups {
  groups {
    data {
      id
      name
      workerGroup
      userAccounts {
        id
        name
      }
      groupPermissions {
        permissionId
        value
        id
      }
      groupInUseData {
        alertSubscriptionCount
        analysisCount
        workOrderTypeCount
      }
    }
  }
  permissions {
    data {
      id
      displayName
      category
      codeName
    }
  }
  userAccounts {
    data {
      id
      name
      userGroups {
        id
      }
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "groups": {
                "type": "GroupResponse",
                "keyRaw": "groups",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Group",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "workerGroup": {
                                        "type": "Boolean",
                                        "keyRaw": "workerGroup",
                                        "visible": true
                                    },

                                    "userAccounts": {
                                        "type": "UserAccount",
                                        "keyRaw": "userAccounts",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "groupPermissions": {
                                        "type": "GroupPermission",
                                        "keyRaw": "groupPermissions",

                                        "selection": {
                                            "fields": {
                                                "permissionId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "permissionId",
                                                    "visible": true
                                                },

                                                "value": {
                                                    "type": "PermissionValue",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "groupInUseData": {
                                        "type": "GroupInUseData",
                                        "keyRaw": "groupInUseData",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "alertSubscriptionCount": {
                                                    "type": "Int",
                                                    "keyRaw": "alertSubscriptionCount",
                                                    "visible": true
                                                },

                                                "analysisCount": {
                                                    "type": "Int",
                                                    "keyRaw": "analysisCount",
                                                    "visible": true
                                                },

                                                "workOrderTypeCount": {
                                                    "type": "Int",
                                                    "keyRaw": "workOrderTypeCount",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "permissions": {
                "type": "PermissionResponse",
                "keyRaw": "permissions",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Permission",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "displayName": {
                                        "type": "String",
                                        "keyRaw": "displayName",
                                        "visible": true
                                    },

                                    "category": {
                                        "type": "String",
                                        "keyRaw": "category",
                                        "visible": true
                                    },

                                    "codeName": {
                                        "type": "String",
                                        "keyRaw": "codeName",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "userAccounts": {
                "type": "UserAccountResponse",
                "keyRaw": "userAccounts",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "UserAccount",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "userGroups": {
                                        "type": "Group",
                                        "keyRaw": "userGroups",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=21d69fd538278f942c5b3d4ee491bbeb7814a8cd39f5445e81ab247ab9d239cb";