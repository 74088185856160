import artifact from '$houdini/artifacts/UpdateProductBatches'
import { MutationStore } from '../runtime/stores/mutation'

export class UpdateProductBatchesStore extends MutationStore {
	constructor() {
		super({
			artifact,
		})
	}
}
