<script lang="ts">
	import type { WorkOrderChangelog$result } from '$houdini/index'
	import type { WritableDeep } from 'type-fest'
	import type { i18n } from 'i18next'

	import Checkbox from '@isoftdata/svelte-checkbox'
	import Table, { Td } from '@isoftdata/svelte-table'
	import { createEventDispatcher, getContext } from 'svelte'

	type ChangelogRow = WritableDeep<WorkOrderChangelog$result['workOrderChangelog'][number]>
	type Change = ChangelogRow['changes'][number]

	export let selectedGroup: ChangelogRow
	export let showHistoricalValues = false

	const dispatch = createEventDispatcher<{
		showHistoricalValuesChange: boolean
	}>()
	const { t: translate } = getContext<i18n>('i18next')

	function formatValue(record: Change, key: 'oldValue' | 'newValue') {
		const value = record[key]
		if (record.type === 'DATE' && value) {
			return new Date(`${value}Z`).toLocaleString()
		}
		return value
	}
</script>

<Checkbox
	label={translate('changelog.showHistoricalValuesLabel', 'Show historical values for unmodified fields')}
	bind:checked={showHistoricalValues}
	on:change={() => dispatch('showHistoricalValuesChange', showHistoricalValues)}
></Checkbox>
<Table
	responsive
	stickyHeader
	parentStyle="max-height: 60vh"
	class="text-nowrap"
	idProp="column"
	columns={[
		{
			name: translate('changelog.fieldColumn', 'Field'),
			property: 'name',
			defaultSortColumn: true,
		},
		{
			name: translate('changelog.oldValueColumn', 'Old Value'),
			property: 'oldValue',
		},
		{
			name: translate('changelog.newValueColumn', 'New Value'),
			property: 'newValue',
		},
	]}
	rows={selectedGroup.changes}
	let:row
>
	<tr class:table-secondary={selectedGroup.event === 'UPDATE' && row.oldValue === row.newValue}>
		<Td property="name">{row.name}</Td>
		<Td property="oldValue">{formatValue(row, 'oldValue')}</Td>
		<Td property="newValue">{formatValue(row, 'newValue')}</Td>
	</tr>
</Table>
