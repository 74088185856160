<script lang="ts">
	// Don't need JS for this component, but you'll get errors if you remove this script tag
</script>

<!-- @component
	This component aims to standardize card footers, particularly when you want to have buttons aligned to both the left and right, and handle wrapping as gracefully as possible.

	If you don't need right-aligned buttons, you can probably just roll your own div.card-footer
-->

<div
	class="card-footer d-flex justify-content-between align-items-start flex-column flex-md-row"
	style="gap: 0.25rem"
>
	<div
		class="d-flex flex-wrap"
		style="gap: 0.25rem"
	>
		<slot></slot>
	</div>
	{#if $$slots.right}
		<div
			class="d-flex flex-wrap justify-content-md-end"
			style="gap: 0.25rem"
		>
			<slot name="right"></slot>
		</div>
	{/if}
</div>
