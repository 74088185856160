export default {
    "name": "LoadInvestigationTriggers",
    "kind": "HoudiniQuery",
    "hash": "a7920ebfbc2f036d97f78d489c6249f10a4f35f62efe42fdf668d83404ce624d",

    "raw": `query LoadInvestigationTriggers($investigationTriggerFilter: InvestigationTriggerFilter, $analysesFilter: AnalysisFilter, $locationsFilter: LocationFilter, $productsFilter: ProductFilter, $pagination: PaginatedInput) {
  investigationTriggers(
    filter: $investigationTriggerFilter
    pagination: $pagination
  ) {
    data {
      id
      description
      active
      resultStatus
      resultCount
      period
      periodType
      defaultComments
      defaultInvestigationType
      defaultStatus
      retestMode
      daysUntilInvestigationDue
      expansionMethod
      expansionSize
      sampleCollectionCount
      retestDocumentCount
      daysBetweenRetests
      analysisOptionId
      analysisOption {
        id
        option
        analysisId
      }
      plantId
      locationId
      severityClassId
      productId
      retestWorkOrderTypeId
      retestAnalysisId
    }
  }
  plants(pagination: $pagination) {
    data {
      id
      name
      code
    }
  }
  analyses(filter: $analysesFilter, pagination: $pagination) {
    data {
      id
      name
      inUseAtPlantIDs
      active
      options {
        id
        option
      }
    }
  }
  locations(filter: $locationsFilter, pagination: $pagination) {
    data {
      id
      location
      plantId
      testable
    }
  }
  severityClasses(pagination: $pagination) {
    data {
      id
      name
      plantId
    }
  }
  products(filter: $productsFilter, pagination: $pagination) {
    data {
      id
      name
      active
      inUseAtPlants {
        id
      }
    }
  }
  workOrderTypes(pagination: $pagination) {
    data {
      id
      name
      inUseAtPlantIDs
    }
  }
  allInvestigationTypes
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "investigationTriggers": {
                "type": "InvestigationTriggerResponse",
                "keyRaw": "investigationTriggers(filter: $investigationTriggerFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "InvestigationTrigger",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "Float",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "description": {
                                        "type": "String",
                                        "keyRaw": "description",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "resultStatus": {
                                        "type": "InvestigationTriggerResultStatus",
                                        "keyRaw": "resultStatus",
                                        "visible": true
                                    },

                                    "resultCount": {
                                        "type": "PositiveInt",
                                        "keyRaw": "resultCount",
                                        "visible": true
                                    },

                                    "period": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "period",
                                        "visible": true
                                    },

                                    "periodType": {
                                        "type": "InvestigationTriggerPeriodType",
                                        "keyRaw": "periodType",
                                        "visible": true
                                    },

                                    "defaultComments": {
                                        "type": "String",
                                        "keyRaw": "defaultComments",
                                        "visible": true
                                    },

                                    "defaultInvestigationType": {
                                        "type": "String",
                                        "keyRaw": "defaultInvestigationType",
                                        "visible": true
                                    },

                                    "defaultStatus": {
                                        "type": "DefaultStatus",
                                        "keyRaw": "defaultStatus",
                                        "visible": true
                                    },

                                    "retestMode": {
                                        "type": "RetestMode",
                                        "keyRaw": "retestMode",
                                        "visible": true
                                    },

                                    "daysUntilInvestigationDue": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "daysUntilInvestigationDue",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "expansionMethod": {
                                        "type": "ExpansionMethod",
                                        "keyRaw": "expansionMethod",
                                        "visible": true
                                    },

                                    "expansionSize": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "expansionSize",
                                        "visible": true
                                    },

                                    "sampleCollectionCount": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "sampleCollectionCount",
                                        "visible": true
                                    },

                                    "retestDocumentCount": {
                                        "type": "Int",
                                        "keyRaw": "retestDocumentCount",
                                        "visible": true
                                    },

                                    "daysBetweenRetests": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "daysBetweenRetests",
                                        "visible": true
                                    },

                                    "analysisOptionId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "analysisOptionId",
                                        "visible": true
                                    },

                                    "analysisOption": {
                                        "type": "AnalysisOption",
                                        "keyRaw": "analysisOption",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "option": {
                                                    "type": "String",
                                                    "keyRaw": "option",
                                                    "visible": true
                                                },

                                                "analysisId": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "analysisId",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "locationId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "locationId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "severityClassId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "severityClassId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "productId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "productId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retestWorkOrderTypeId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "retestWorkOrderTypeId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "retestAnalysisId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "retestAnalysisId",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "plants": {
                "type": "PlantResponse",
                "keyRaw": "plants(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Plant",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "code": {
                                        "type": "String",
                                        "keyRaw": "code",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "analyses": {
                "type": "AnalysisResponse",
                "keyRaw": "analyses(filter: $analysesFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Analysis",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "inUseAtPlantIDs": {
                                        "type": "PositiveInt",
                                        "keyRaw": "inUseAtPlantIDs",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "options": {
                                        "type": "AnalysisOption",
                                        "keyRaw": "options",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "option": {
                                                    "type": "String",
                                                    "keyRaw": "option",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "locations": {
                "type": "LocationResponse",
                "keyRaw": "locations(filter: $locationsFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Location",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "location": {
                                        "type": "String",
                                        "keyRaw": "location",
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "visible": true
                                    },

                                    "testable": {
                                        "type": "Boolean",
                                        "keyRaw": "testable",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "severityClasses": {
                "type": "SeverityClassResponse",
                "keyRaw": "severityClasses(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "SeverityClass",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "products": {
                "type": "ProductResponse",
                "keyRaw": "products(filter: $productsFilter, pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Product",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "inUseAtPlants": {
                                        "type": "Plant",
                                        "keyRaw": "inUseAtPlants",

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "workOrderTypes": {
                "type": "WorkOrderTypeResponse",
                "keyRaw": "workOrderTypes(pagination: $pagination)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "WorkOrderType",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "inUseAtPlantIDs": {
                                        "type": "PositiveInt",
                                        "keyRaw": "inUseAtPlantIDs",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            },

            "allInvestigationTypes": {
                "type": "String",
                "keyRaw": "allInvestigationTypes",
                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "investigationTriggerFilter": "InvestigationTriggerFilter",
            "analysesFilter": "AnalysisFilter",
            "locationsFilter": "LocationFilter",
            "productsFilter": "ProductFilter",
            "pagination": "PaginatedInput"
        },

        "types": {
            "InvestigationTriggerFilter": {
                "plantId": "PositiveInt"
            },

            "AnalysisFilter": {
                "activeOnly": "Boolean",
                "category": "NonEmptyString",
                "ids": "PositiveInt",
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "type": "AnalysisType"
            },

            "ProcessZoneFilter": {
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt"
            },

            "ProductProximityFilter": {
                "name": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt"
            },

            "LocationFilter": {
                "activeOnly": "Boolean",
                "location": "NonEmptyString",
                "plantIds": "PositiveInt",
                "plants": "PositiveInt",
                "processZone": "ProcessZoneFilter",
                "productProximity": "ProductProximityFilter",
                "testableOnly": "Boolean"
            },

            "ProductFilter": {
                "active": "Boolean",
                "activeOnly": "Boolean",
                "plantIds": "PositiveInt",
                "plantIdsFilteringByChildren": "PositiveInt",
                "plants": "PositiveInt",
                "productName": "NonEmptyString",
                "productType": "ProductType"
            },

            "PaginatedInput": {
                "pageNumber": "PositiveInt",
                "pageSize": "NonNegativeInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=576746ecc152334a474da5b11072ceaa467d453ce42eb158b5fe013fe2ae62c4";