export default {
    "name": "GetReports",
    "kind": "HoudiniQuery",
    "hash": "cc91e91a336005807f9c0d88326a11e861b2a848dae4c5769c9aa0527d14a910",

    "raw": `query GetReports($reportsFilter: ReportsFilter) {
  reports(filter: $reportsFilter, pagination: {pageSize: 0}) {
    data {
      id
      name
      type
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "reports": {
                "type": "ReportResponse",
                "keyRaw": "reports(filter: $reportsFilter, pagination: {pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "Report",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "String",
                                        "keyRaw": "type",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "reportsFilter": "ReportsFilter"
        },

        "types": {
            "ReportsFilter": {
                "type": "String",
                "userVisible": "Boolean"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=3aad252c1abc21362a27d1f50255fb4ac476979e6882d04f41f5a3ef9635e55a";