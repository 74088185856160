export default {
    "name": "CreateProductBatches",
    "kind": "HoudiniMutation",
    "hash": "e4c290d31d77428b876cb7c2dd6520eccd11fdfe2fd000dd6d8307b520d13b3f",

    "raw": `mutation CreateProductBatches($productBatches: [NewProductBatch!]!) {
  createProductBatches(productBatches: $productBatches) {
    id
    plantId
    name
    productId
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "createProductBatches": {
                "type": "ProductBatch",
                "keyRaw": "createProductBatches(productBatches: $productBatches)",

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "plantId": {
                            "type": "PositiveInt",
                            "keyRaw": "plantId",
                            "visible": true
                        },

                        "name": {
                            "type": "String",
                            "keyRaw": "name",
                            "visible": true
                        },

                        "productId": {
                            "type": "PositiveInt",
                            "keyRaw": "productId",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "productBatches": "NewProductBatch"
        },

        "types": {
            "NewProductBatch": {
                "description": "String",
                "end": "DateTime",
                "locationId": "PositiveInt",
                "name": "String",
                "plantId": "PositiveInt",
                "productId": "PositiveInt",
                "start": "DateTime",
                "status": "ProductBatchStatus"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=8ff9a6fcb14e99365db7801a8434e3e662db719b77b575e298a6adacac3c5e33";