import { getEventValue } from '@isoftdata/browser-event'
import { formatDate, subMilliseconds } from 'date-fns'
import { getTimezoneOffset, toZonedTime } from 'date-fns-tz'
import { minToMs } from 'utility/to-milliseconds'

/** Convert a date to the logged-in plant's timezone for display in a datetime-local input */
export function formatDateTimeForInput(date: string | null, tz: string): string {
	const timeZoneIsValid = isValidTimeZone(tz)
	if (!date) {
		return ''
	} else if (!timeZoneIsValid) {
		return formatDate(new Date(date), "yyyy-MM-dd'T'HH:mm:ss")
	}
	return formatDate(toZonedTime(new Date(date), tz), "yyyy-MM-dd'T'HH:mm:ss")
}

/** datetime-local inputs give us dates zoned as the device's timezone,
 * so we have to subtract the difference between the plant and device timezones
 * in order to make it appear correct, without changing the actual timezone of the date */
export function addPlantTzOffset(userTzDate: Date, tz: string): string {
	const timeZoneIsValid = isValidTimeZone(tz)

	if (!timeZoneIsValid) {
		return userTzDate.toISOString()
	}

	const plantUtcOffset = getTimezoneOffset(tz)
	const userUtcOffset = minToMs(userTzDate.getTimezoneOffset())
	const diff = plantUtcOffset + userUtcOffset
	const plantTzDate = subMilliseconds(userTzDate, diff)
	return plantTzDate.toISOString()
}

/** Gets the value of a datetime-local input and converts it to a UTC date string rather than the User Agent's timezone */
export function getEventUtcDate(event: Event, tz: string): string {
	return addPlantTzOffset(new Date(getEventValue(event)), tz)
}

/** Checks that Intl.DateTimeFormat can format a date in the given time zone successfully. Returns true if so, false if not. */
export function isValidTimeZone(timeZoneString: string) {
	if (!timeZoneString || timeZoneString.startsWith('SystemV/')) {
		return false
	}

	try {
		new Intl.DateTimeFormat(undefined, { timeZone: timeZoneString })
		return true
	} catch (error) {
		return false
	}
}
