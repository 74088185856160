export default {
    "name": "WorkOrderChangelog",
    "kind": "HoudiniQuery",
    "hash": "1fd4d737a10967bc81fd062f405e067f32ebbcf30b70922e463298f6f4935ebc",

    "raw": `query WorkOrderChangelog($filter: WorkOrderChangelogFilter!) {
  workOrderChangelog(filter: $filter) {
    rowId
    client
    event
    id
    parentRowId
    table
    recordType
    transactionDate
    userAccountId
    userAccount {
      name
      id
    }
    description
    changes: formattedChanges {
      name
      column
      newValue
      oldValue
      type
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "workOrderChangelog": {
                "type": "FormattedChangelogGroup",
                "keyRaw": "workOrderChangelog(filter: $filter)",

                "selection": {
                    "fields": {
                        "rowId": {
                            "type": "String",
                            "keyRaw": "rowId",
                            "visible": true
                        },

                        "client": {
                            "type": "String",
                            "keyRaw": "client",
                            "visible": true
                        },

                        "event": {
                            "type": "ChangeEventType",
                            "keyRaw": "event",
                            "visible": true
                        },

                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        },

                        "parentRowId": {
                            "type": "String",
                            "keyRaw": "parentRowId",
                            "nullable": true,
                            "visible": true
                        },

                        "table": {
                            "type": "String",
                            "keyRaw": "table",
                            "visible": true
                        },

                        "recordType": {
                            "type": "String",
                            "keyRaw": "recordType",
                            "visible": true
                        },

                        "transactionDate": {
                            "type": "DateTime",
                            "keyRaw": "transactionDate",
                            "visible": true
                        },

                        "userAccountId": {
                            "type": "Float",
                            "keyRaw": "userAccountId",
                            "nullable": true,
                            "visible": true
                        },

                        "userAccount": {
                            "type": "UserAccount",
                            "keyRaw": "userAccount",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        },

                        "description": {
                            "type": "String",
                            "keyRaw": "description",
                            "visible": true
                        },

                        "changes": {
                            "type": "FormattedChange",
                            "keyRaw": "changes",

                            "selection": {
                                "fields": {
                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "column": {
                                        "type": "String",
                                        "keyRaw": "column",
                                        "visible": true
                                    },

                                    "newValue": {
                                        "type": "String",
                                        "keyRaw": "newValue",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "oldValue": {
                                        "type": "String",
                                        "keyRaw": "oldValue",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "type": {
                                        "type": "ChangeDataType",
                                        "keyRaw": "type",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "filter": "WorkOrderChangelogFilter"
        },

        "types": {
            "WorkOrderChangelogFilter": {
                "dateFrom": "DateTime",
                "dateTo": "DateTime",
                "event": "ChangeEventType",
                "sampleIds": "PositiveInt",
                "showHistoricalValues": "Boolean",
                "userAccountId": "PositiveInt",
                "workOrderId": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=903dc7d87472c697016e041488089bef1eb9f091e86e2e1dafdf48ef581f26cd";