export default {
    "name": "GetFavoriteReports",
    "kind": "HoudiniQuery",
    "hash": "41c674848d6026388a938ad89856e1664c80559a0fb3facbc501dbf4bf40962f",

    "raw": `query GetFavoriteReports {
  session {
    user {
      favoriteReports: getUserSetting(
        lookup: {category: "Report Viewer", settingType: INTERFACE_HISTORY, name: "Favorite reports", defaultValue: ""}
      ) {
        value
      }
      id
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "session": {
                "type": "Session",
                "keyRaw": "session",
                "nullable": true,

                "selection": {
                    "fields": {
                        "user": {
                            "type": "UserAccount",
                            "keyRaw": "user",
                            "nullable": true,

                            "selection": {
                                "fields": {
                                    "favoriteReports": {
                                        "type": "SettingResult",
                                        "keyRaw": "favoriteReports(lookup: {category: \"Report Viewer\", settingType: INTERFACE_HISTORY, name: \"Favorite reports\", defaultValue: \"\"})",

                                        "selection": {
                                            "fields": {
                                                "value": {
                                                    "type": "String",
                                                    "keyRaw": "value",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=0d4f96b41e63743fbe209a990705e4e84e940cf7299efbd6d8ba32b80eaeed61";