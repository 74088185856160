export default {
    "name": "GetPrinters",
    "kind": "HoudiniQuery",
    "hash": "dd8d344f6634e5f40733e684af0681ea04981ee9d5fdf44c30737a780e31a50d",

    "raw": `query GetPrinters($filter: ReportQueuePrinterFilter) {
  printers: reportQueuePrinters(filter: $filter, pagination: {pageSize: 0}) {
    data {
      dateLastSeen
      displayName
      hidden
      id
      name
      online
      plantId
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "printers": {
                "type": "ReportQueuePrinterResponse",
                "keyRaw": "printers(filter: $filter, pagination: {pageSize: 0})",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "ReportQueuePrinter",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "dateLastSeen": {
                                        "type": "DateTime",
                                        "keyRaw": "dateLastSeen",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "displayName": {
                                        "type": "String",
                                        "keyRaw": "displayName",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "hidden": {
                                        "type": "Boolean",
                                        "keyRaw": "hidden",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "name": {
                                        "type": "String",
                                        "keyRaw": "name",
                                        "visible": true
                                    },

                                    "online": {
                                        "type": "Boolean",
                                        "keyRaw": "online",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "NonNegativeInt",
                                        "keyRaw": "plantId",
                                        "nullable": true,
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "filter": "ReportQueuePrinterFilter"
        },

        "types": {
            "ReportQueuePrinterFilter": {
                "hidden": "Boolean",
                "online": "Boolean",
                "plantIds": "PositiveInt",
                "reportTypes": "String"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=246d184ede9da3f7e8ce1c09daf0f140178476fb90ac796cecf2d75321cf2330";