<svelte:options accessors />

<script lang="ts">
	import type { NewWorkOrderTypeInput, UpdateWorkOrderTypeInput, WoTypeConfigData$result } from '$houdini'
	import type { ConditionalKeys, ConditionalPick, WritableDeep, Merge } from 'type-fest'
	import type { Mediator, SvelteAsr, i18n } from 'types/common'
	import type { SaveResetProps } from '../configuration'

	import Table, { Td, type Column, type IndexedRowProps } from '@isoftdata/svelte-table'
	import Input from '@isoftdata/svelte-input'
	import Select from '@isoftdata/svelte-select'
	import Button from '@isoftdata/svelte-button'
	import SiteAutocomplete from '@isoftdata/svelte-site-autocomplete'
	import Icon from '@isoftdata/svelte-icon'

	import makeCrudStore from '@isoftdata/svelte-store-crud'
	import { v4 as uuid } from '@lukeed/uuid'
	import { getContext, tick } from 'svelte'
	import pMap from 'p-map'
	import hasPermission from 'utility/has-permission'
	import { writable } from 'svelte/store'
	import { graphql } from '$houdini'
	import makeAddRemoveStore from 'stores/add-remove-store'
	import { getEventChecked, getEventValue, getEventValueEnum } from '@isoftdata/browser-event'

	type Plant = WoTypeConfigData$result['plants']['data'][number]
	type WorkOrderType = Merge<WritableDeep<WoTypeConfigData$result['workOrderTypes']['data'][number]>, { id: number | null; uuid: string }>
	type WorkOrderTypeRow = WorkOrderType & IndexedRowProps
	type Group = WoTypeConfigData$result['groups']['data'][number]
	type Analysis = WoTypeConfigData$result['analyses']['data'][number]

	export let workOrderTypes: Array<WorkOrderType>
	export let groups: Array<Group>
	export let analyses: Array<Analysis>
	export let plants: Array<Plant>
	export let selectedPlant: Plant
	export let asr: SvelteAsr
	export let saveResetProps: SaveResetProps
	// prop so we can access in canLeaveState
	export let hasUnsavedChanges = false
	$: hasUnsavedChanges = Object.keys($woTypeCrudStore.created).length > 0 || Object.keys($woTypeCrudStore.updated).length > 0 || Object.keys($woTypeCrudStore.deleted).length > 0
	// Need to do this so selectedPlant is set back to the plant we're on if we cancel a state change
	$: selectedPlant = plants.find(plant => plant.id === parseInt((asr.getActiveState().parameters as { plantId: string }).plantId ?? selectedPlant.id, 10)) ?? selectedPlant
	let woTypeCrudStore = makeCrudStore<WorkOrderType, 'uuid'>('uuid')
	let selectedRowIds: Array<string> = []

	$: $saveResetProps = {
		save,
		resetHref: asr.makePath(null, { lastResetTime: Date.now(), plantId: selectedPlant.id }),
		disabled: !hasUnsavedChanges,
	}

	// These columns are always readonly on desktop. Might as well keep them around, but hide them since they're not necessary.
	let table: Table<WorkOrderType>
	$: {
		table?.setColumnVisibility('showSampling', false)
		table?.setColumnVisibility('showTasks', false)
	}
	$: selectedWoType = selectedRowIds.length ? (workOrderTypes.find(woType => woType.uuid === selectedRowIds[0]) ?? null) : null

	const { format: numberFormat } = new Intl.NumberFormat()
	/** Editing "Global" fields on a WO type requires you be able to have permissions at every plant it's at, so store a map of which types we can edit.*/
	const canEditTypeStore = writable(
		workOrderTypes.reduce((acc: Record<string, boolean>, woType) => {
			acc[woType.uuid] = woType.inUseAtPlantIDs?.length
				? hasPermission('CONFIGURATION_CAN_CONFIGURE_WORK_ORDER_TYPES', null) || woType.inUseAtPlantIDs?.every(plantId => hasPermission('CONFIGURATION_CAN_CONFIGURE_WORK_ORDER_TYPES', plantId))
				: true
			return acc
		}, {}),
	)
	// overkill for what we're doing here since we're only adding/removing one plant at a time, but I already built it and it works.
	const woInUseStore = makeAddRemoveStore<number>()
	const mediator = getContext<Mediator>('mediator')
	const { t: translate } = getContext<i18n>('i18next')
	const columns: Array<Column> = [
		{
			name: '',
			icon: 'save',
			property: 'id',
			sortType: false,
			align: 'center',
			title: 'Rows with a save icon have unsaved changes, and will be saved when you hit the "Save" button',
		},
		{
			name: translate('configuration.woTypes.nameColumn', 'Name'),
			property: 'name',
			title: translate('configuration.woTypes.nameColumnTitle', 'The name of the work order type'),
			minWidth: '200px',
		},
		{
			name: translate('configuration.woTypes.woCountColumn', 'WO Count'),
			property: 'workOrderCount',
			title: translate('configuration.woTypes.workOrderCountColumnTitle', 'How many work orders use this type currently'),
			numeric: true,
		},
		{
			name: translate('configuration.woTypes.inUseColumn', `In Use @${selectedPlant.code}`),
			property: 'inUseAtPlantIDs',
			title: translate('configuration.woTypes.inUseAtPlantIDsColumnTitle', 'Whether this WO type is in use at the current plant'),
			sortType: false,
			align: 'center',
		},
		{
			name: translate('configuration.woTypes.dueDateColumn', 'Due Date'),
			property: 'showDue',
			title: translate('configuration.woTypes.showDueColumnTitle', "Whether WOs of this type support having a 'due date'"),
			align: 'center',
		},
		{
			name: translate('configuration.woTypes.daysTillDueColumn', 'Days Until Due'),
			property: 'daysTillDue',
			title: translate('configuration.woTypes.daysTillDueColumnTitle', 'If this is filled out, it is the number of days a new WO has until it is due (by default)'),
			minWidth: '100px',
			numeric: true,
		},
		{
			name: translate('configuration.woTypes.verificationRequiredColumn', 'Verification Required'),
			property: 'verificationRequired',
			title: translate('configuration.woTypes.verificationRequiredColumnTitle', 'Whether WOs of this type require that a verification step be processed before they can be finalized.'),
			align: 'center',
		},
		{
			name: translate('configuration.woTypes.daysTillVerificationDueColumn', 'Days Until Verification Due'),
			property: 'daysTillVerificationDue',
			title: translate(
				'configuration.woTypes.daysTillVerificationDueColumnTitle',
				'If this is filled out, it is the number of days after a WO has been filled out before verification is due (by default)',
			),
			minWidth: '100px',
			numeric: true,
		},
		{
			name: translate('configuration.woTypes.verificationGroupColumn', 'Verification Group'),
			property: 'defaultVerificationGroup[name]',
			title: translate('configuration.woTypes.defaultVerificationGroupColumnTitle', 'The default user group that the WO will be assigned to for verification (Leave blank to choose per WO)'),
			minWidth: '175px',
		},
		{
			name: translate('configuration.woTypes.showSamplingColumn', 'Samples Page'),
			property: 'showSampling',
			title: translate('configuration.woTypes.showSamplingColumnTitle', 'Whether WOs of this type show the sampling page (used to collect testing data)'),
			sortType: false,
			align: 'center',
		},
		{
			name: translate('configuration.woTypes.showProductColumn', 'Products / Ingredients'),
			property: 'showProduct',
			title: translate('configuration.woTypes.showProductColumnTitle', 'Whether samples on WOs of this type allow selection of products, ingredients, or neither.'),
			minWidth: '150px',
		},
		{
			name: translate('configuration.woTypes.showLocationColumn', 'Locations'),
			property: 'showLocation',
			title: translate('configuration.woTypes.showLocationColumnTitle', 'Whether WOs of this type can select a sampling site (location)'),
			align: 'center',
		},
		{
			name: translate('configuration.woTypes.showLocationDescriptionColumn', 'Location Description'),
			property: 'showLocationDescription',
			title: translate('configuration.woTypes.showLocationDescriptionColumnTitle', 'Whether WOs of this type show the full location description for the selected location'),
			align: 'center',
		},
		{
			name: translate('configuration.woTypes.showSamplingDetailColumn', 'Collection Detail'),
			property: 'showSamplingDetail',
			title: translate('configuration.woTypes.showSamplingDetailColumnTitle', 'Whether WOs of this type show the sampling detail columns, such as: Sampled By, Sampled On, and Sampling Notes'),
			align: 'center',
		},
		{
			name: translate('configuration.woTypes.showTestingDetailColumn', 'Testing Detail'),
			property: 'showTestingDetail',
			title: translate('configuration.woTypes.showTestingDetailColumnTitle', 'Whether WOs of this type show the testing detail columns, such as: Tested By, Tested Began/Ended, and Testing Notes'),
			align: 'center',
		},
		{
			name: translate('configuration.woTypes.defaultAnalysisColumn', 'Default Analysis'),
			property: 'defaultAnalysis[name]',
			title: translate('configuration.woTypes.defaultAnalysisColumnTitle', 'The default analysis for new samples on WOs of this type'),
			minWidth: '200px',
		},
		{
			name: translate('configuration.woTypes.defaultGroupColumn', 'Default Group'),
			property: 'defaultGroup[name]',
			title: translate('configuration.woTypes.defaultGroupColumnTitle', 'The default user group to assign new WOs of this type to'),
			minWidth: '175px',
		},
		// this is the only column I've moved from the desktop order, per WW-7 saying it should be close to Default Group
		{
			name: translate('configuration.woTypes.visibleGroupColumn', 'Visible Group'),
			property: 'visibleGroup[name]',
			title: translate('configuration.woTypes.visibleGroupColumnTitle', '(Optional) If specified, only users in this group will be able use this WO type or see WOs using this type'),
			minWidth: '175px',
		},
		{
			name: translate('configuration.woTypes.showRecipesColumn', 'Recipes Page'),
			property: 'showRecipes',
			title: translate('configuration.woTypes.showRecipesColumnTitle', 'Whether WOs of this type show the recipes page (used for creating production WOs)'),
			align: 'center',
		},
		{
			name: translate('configuration.woTypes.showTasksColumn', 'Tasks Page'),
			property: 'showTasks',
			title: translate('configuration.woTypes.showTasksColumnTitle', 'Whether WOs of this type show the tasks page (used for tracking tasks to be performed)'),
			align: 'center',
		},
		{
			name: translate('configuration.woTypes.titleRequiredColumn', 'Title Required'),
			property: 'titleRequired',
			title: translate('configuration.woTypes.titleRequiredColumnTitle', 'Whether WOs of this type require a title/description/reference # before they can be closed.'),
			align: 'center',
		},
		{
			name: translate('configuration.woTypes.productBatchRequiredColumn', 'Batch Required'),
			property: 'productBatchRequired',
			title: translate(
				'configuration.woTypes.productBatchRequiredColumnTitle',
				"Whether WOs of this type require, show, or hide the 'product batch' dropdown. A WO type must allow products or ingredients to use this feature.",
			),
			minWidth: '125px',
		},
	]

	// #region Types & Guards
	function isProductBatchOption(option: string): option is 'SHOW' | 'HIDE' | 'REQUIRE' {
		return ['SHOW', 'HIDE', 'REQUIRE'].includes(option)
	}

	function isProductOrIngredientOption(option: string): option is 'PRODUCT' | 'INGREDIENT' | 'BOTH' | 'NONE' {
		return ['PRODUCT', 'INGREDIENT', 'BOTH', 'NONE'].includes(option)
	}

	function intTargetValueOrNull(event: Event): number | null {
		return parseInt(getEventValue(event), 10) || null
	}

	type WoTypeObjKeys = ConditionalKeys<WorkOrderType, { id: number; name: string } | null>
	type WoTypeObjValues = ConditionalPick<WorkOrderType, { id: number; name: string } | null>
	type WoTypeNonNullObjValues = Exclude<WoTypeObjValues[WoTypeObjKeys], null>
	// #endregion
	// #region Event Handlers
	async function createWoType() {
		const newWoType: WorkOrderType = {
			id: null,
			daysTillDue: null,
			daysTillVerificationDue: null,
			defaultAnalysis: null,
			defaultGroup: null,
			defaultVerificationGroup: null,
			inUseAtPlantIDs: [selectedPlant.id],
			name: '',
			productBatchRequired: 'SHOW',
			showDue: true,
			showLocation: true,
			showLocationDescription: true,
			showProduct: 'PRODUCT',
			showRecipes: false,
			showSampling: true,
			showSamplingDetail: true,
			showTasks: false,
			showTestingDetail: true,
			titleRequired: false,
			uuid: uuid(),
			verificationRequired: false,
			visibleGroup: null,
			workOrderCount: 0,
		}

		woTypeCrudStore.create(newWoType)
		workOrderTypes.push(newWoType)
		workOrderTypes = workOrderTypes
		$canEditTypeStore[newWoType.uuid] = true
		table?.setPageVisibleByItemIndex(workOrderTypes.length - 1)
		await tick()
		document.querySelector<HTMLInputElement>('tr:last-child input')?.focus()
	}

	function onObjPropChange<K extends WoTypeObjKeys>(row: WorkOrderTypeRow, property: K, objId: WoTypeNonNullObjValues['id'] | null, list: Array<Analysis> | Array<Group> = groups) {
		if (!objId) {
			return onChange(row, property, null)
		}
		const obj = list.find(group => group.id === objId)
		if (obj) {
			onChange(row, property, obj)
		}
	}

	function onChange<K extends keyof WorkOrderType>(row: WorkOrderTypeRow, property: K, value: WorkOrderType[K]) {
		workOrderTypes[row.originalIndex][property] = value
		woTypeCrudStore.update(workOrderTypes[row.originalIndex])
	}

	function onInUseChange(e: Event, row: WorkOrderTypeRow) {
		let inUseAtPlantIDs = workOrderTypes[row.originalIndex].inUseAtPlantIDs

		if (getEventChecked(e)) {
			inUseAtPlantIDs = [...inUseAtPlantIDs, selectedPlant.id]
			if (row.id) {
				woInUseStore.add(row.uuid, selectedPlant.id)
			}
		} else {
			inUseAtPlantIDs = inUseAtPlantIDs.filter(plantId => plantId !== selectedPlant.id)
			if (row.id) {
				woInUseStore.remove(row.uuid, selectedPlant.id)
			}
		}
		onChange(row, 'inUseAtPlantIDs', inUseAtPlantIDs)
	}

	function onDelete() {
		if (selectedWoType && selectedWoType.workOrderCount) {
			alert(
				translate(
					'woTypes.deleteInUseError',
					`This work order type is in use on {{workOrderCount}} saved work orders.

A work order type cannot be deleted while any work orders are using it.`,
					{ workOrderCount: selectedWoType.workOrderCount },
				),
			)
			return
		} else if (
			selectedWoType &&
			(!selectedWoType.id ||
				confirm(
					translate(
						'woTypes.confirmDelete',
						`Are you sure you want to delete this work order type?

After saving, this cannot be undone.`,
					),
				))
		) {
			woTypeCrudStore.delete(selectedWoType)
			selectedRowIds = []
			workOrderTypes = workOrderTypes.filter(woType => selectedWoType!.uuid !== woType.uuid)
		}
	}
	// #endregion
	// #region Formatters and Saving
	function formatWoTypeForCreate({ id, defaultAnalysis, defaultGroup, defaultVerificationGroup, visibleGroup, uuid, workOrderCount, ...woType }: WorkOrderType): NewWorkOrderTypeInput {
		return {
			...woType,
			defaultAnalysisId: defaultAnalysis?.id ?? null,
			defaultGroupId: defaultGroup?.id ?? null,
			defaultVerificationGroupId: defaultVerificationGroup?.id ?? null,
			visibleGroupId: visibleGroup?.id ?? null,
		}
	}

	function formatWoTypeForUpdate({
		id,
		defaultAnalysis,
		defaultGroup,
		defaultVerificationGroup,
		visibleGroup,
		uuid,
		workOrderCount,
		inUseAtPlantIDs,
		...woType
	}: WorkOrderType & { id: number }): UpdateWorkOrderTypeInput {
		const updateWoType: UpdateWorkOrderTypeInput = {
			...woType,
			id: id,
			defaultAnalysisId: defaultAnalysis?.id ?? null,
			defaultGroupId: defaultGroup?.id ?? null,
			defaultVerificationGroupId: defaultVerificationGroup?.id ?? null,
			visibleGroupId: visibleGroup?.id ?? null,
		}

		const addPlant = !!woInUseStore.getAddIds(uuid).length
		const removePlant = !!woInUseStore.getRemoveIds(uuid).length

		if (addPlant) {
			updateWoType.inUseAtPlantIDsToAdd = [selectedPlant.id]
		} else if (removePlant) {
			updateWoType.inUseAtPlantIDsToRemove = [selectedPlant.id]
		}

		return updateWoType
	}

	async function save() {
		const typesToCreate = woTypeCrudStore.createdValues
		const typesToUpdate = woTypeCrudStore.updatedValues
		const typesToDelete = woTypeCrudStore.deletedValues

		try {
			if (typesToCreate.length) {
				await pMap(typesToCreate, async woType => {
					await createWoTypeMutation.mutate({
						newWorkOrderType: formatWoTypeForCreate(woType),
					})
				})
			}

			if (typesToUpdate.length) {
				await pMap(typesToUpdate, async woType => {
					if (!woType.id) {
						return Promise.resolve()
					}
					await updateWoTypeMutation.mutate({
						updateWorkOrderType: formatWoTypeForUpdate({
							...woType,
							// have to explicitly set id or typeguard no worky
							id: woType.id,
						}),
					})
				})
			}

			if (typesToDelete.length) {
				await pMap(typesToDelete, async woType => {
					if (!woType.id) {
						return Promise.resolve()
					}
					await deleteWoTypeMutation.mutate({
						deleteWorkOrderTypeId: woType.id.toString(),
					})
				})
			}
			mediator.call('showMessage', {
				type: 'success',
				heading: translate('woTypes.savedHeading', 'Saved!'),
				message: translate('woTypes.savedMessage', 'Work order types saved successfully.'),
				time: 10,
			})
			// set to false to appease route guard
			hasUnsavedChanges = false
			asr.go(null, { lastSavedTime: Date.now() })
		} catch (err) {
			mediator.call('showMessage', {
				type: 'danger',
				heading: translate('woTypes.saveErrorHeading', 'Error Saving Work Order Types'),
				message: (err as Error).message,
				time: false,
			})
		}
	}
	// #endregion
	// #region Query Stores
	const createWoTypeMutation = graphql(`
		mutation CreateWorkOrderType($newWorkOrderType: NewWorkOrderTypeInput!) {
			createWorkOrderType(newWorkOrderType: $newWorkOrderType) {
				id
			}
		}
	`)

	const updateWoTypeMutation = graphql(`
		mutation UpdateWorkOrderType($updateWorkOrderType: UpdateWorkOrderTypeInput!) {
			updateWorkOrderType(updateWorkOrderType: $updateWorkOrderType) {
				id
			}
		}
	`)

	const deleteWoTypeMutation = graphql(`
		mutation DeleteWorkOrderType($deleteWorkOrderTypeId: ID!) {
			deleteWorkOrderType(id: $deleteWorkOrderTypeId)
		}
	`)
	// #endregion
</script>

<Table
	responsive
	filterEnabled
	selectionEnabled
	columnHidingEnabled
	headerColumnClass="col-lg-3 col-md-4 col-sm-6 align-self-end mr-auto"
	filterColumnClass="col-lg-3 col-md-4 col-sm-6 align-self-end"
	{columns}
	rows={workOrderTypes}
	perPageCount={20}
	rowSelectionIdProp="uuid"
	selectionMode="SINGLE"
	multiSelectEnabled={false}
	idProp="uuid"
	bind:selectedRowIds
	bind:this={table}
	let:row
>
	<svelte:fragment slot="header">
		<div class="mb-3">
			<SiteAutocomplete
				label={translate('plant', 'Plant')}
				options={plants}
				value={selectedPlant}
				on:change={event => asr.go(null, { plantId: event.detail.id })}
			/>
		</div>
	</svelte:fragment>
	<tr
		class:table-primary={selectedRowIds.includes(row.uuid)}
		on:click={() => table.rowClick(row)}
	>
		<Td property="id">
			{#if !row.id || row.uuid in $woTypeCrudStore.updated || row.uuid in $woTypeCrudStore.deleted}
				<Icon icon="save"></Icon>
			{/if}
		</Td>
		<Td
			stopPropagation
			property="name"
		>
			<Input
				placeholder={translate('woTypes.namePlaceholder', 'Enter a name')}
				showLabel={false}
				value={row.name}
				disabled={!$canEditTypeStore[row.uuid]}
				on:change={e => onChange(row, 'name', getEventValue(e))}
			></Input>
		</Td>
		<Td property="workOrderCount">
			{numberFormat(row.workOrderCount)}
		</Td>
		<Td
			stopPropagation
			property="inUseAtPlantIDs"
		>
			<input
				type="checkbox"
				checked={row.inUseAtPlantIDs?.includes(selectedPlant.id)}
				on:change={e => onInUseChange(e, row)}
			/>
		</Td>
		<Td
			stopPropagation
			property="showDue"
		>
			<input
				type="checkbox"
				checked={row.showDue}
				disabled={!$canEditTypeStore[row.uuid]}
				on:change={e => onChange(row, 'showDue', getEventChecked(e))}
			/>
		</Td>
		<Td
			stopPropagation
			property="daysTillDue"
		>
			<Input
				showLabel={false}
				type="number"
				placeholder="N/A"
				value={row.showDue ? row.daysTillDue : null}
				disabled={!row.showDue || !$canEditTypeStore[row.uuid]}
				on:change={e => onChange(row, 'daysTillDue', getEventValue(e) ? parseInt(getEventValue(e), 10) : null)}
			></Input>
		</Td>
		<Td
			stopPropagation
			property="verificationRequired"
		>
			<input
				type="checkbox"
				checked={row.verificationRequired}
				disabled={!$canEditTypeStore[row.uuid]}
				on:change={e => onChange(row, 'verificationRequired', getEventChecked(e))}
			/>
		</Td>
		<Td
			stopPropagation
			property="daysTillVerificationDue"
		>
			<Input
				showLabel={false}
				type="number"
				placeholder="N/A"
				value={row.verificationRequired ? row.daysTillVerificationDue : null}
				disabled={!row.verificationRequired || !$canEditTypeStore[row.uuid]}
				on:change={e => onChange(row, 'daysTillVerificationDue', parseInt(getEventValue(e), 10) || null)}
			></Input>
		</Td>
		<Td
			stopPropagation
			property="defaultVerificationGroup[name]"
		>
			<Select
				showLabel={false}
				emptyText="N/A"
				value={row.defaultVerificationGroup?.id ?? null}
				options={groups}
				disabled={!$canEditTypeStore[row.uuid]}
				on:change={e => onObjPropChange(row, 'defaultVerificationGroup', intTargetValueOrNull(e))}
				let:option
			>
				<option value={option?.id ?? null}>{option?.name}</option>
			</Select>
		</Td>
		<Td
			stopPropagation
			property="showSampling"
		>
			<input
				type="checkbox"
				checked={row.showSampling}
				disabled
			/>
		</Td>
		<Td
			stopPropagation
			property="showProduct"
		>
			<Select
				showLabel={false}
				showEmptyOption={false}
				value={row.showProduct}
				disabled={!$canEditTypeStore[row.uuid]}
				on:change={e => isProductOrIngredientOption(getEventValue(e)) && onChange(row, 'showProduct', getEventValueEnum(e, 'PRODUCT', 'INGREDIENT', 'BOTH', 'NONE'))}
			>
				<option value="NONE">{translate('woTypes.showProduct.none', 'None')}</option>
				<option value="BOTH">{translate('woTypes.showProduct.both', 'Both')}</option>
				<option value="PRODUCT">{translate('woTypes.showProduct.product', 'Products')}</option>
				<option value="INGREDIENT">{translate('woTypes.showProduct.ingredient', 'Ingredients')}</option>
			</Select>
		</Td>
		<Td
			stopPropagation
			property="showLocation"
		>
			<input
				type="checkbox"
				checked={row.showLocation}
				disabled={!$canEditTypeStore[row.uuid]}
				on:change={e => onChange(row, 'showLocation', getEventChecked(e))}
			/>
		</Td>
		<Td
			stopPropagation
			property="showLocationDescription"
		>
			<input
				type="checkbox"
				checked={row.showLocationDescription}
				disabled={!$canEditTypeStore[row.uuid]}
				on:change={e => onChange(row, 'showLocationDescription', getEventChecked(e))}
			/>
		</Td>
		<Td
			stopPropagation
			property="showSamplingDetail"
		>
			<input
				type="checkbox"
				checked={row.showSamplingDetail}
				disabled={!$canEditTypeStore[row.uuid]}
				on:change={e => onChange(row, 'showSamplingDetail', getEventChecked(e))}
			/>
		</Td>
		<Td
			stopPropagation
			property="showTestingDetail"
		>
			<input
				type="checkbox"
				checked={row.showTestingDetail}
				disabled={!$canEditTypeStore[row.uuid]}
				on:change={e => onChange(row, 'showTestingDetail', getEventChecked(e))}
			/>
		</Td>
		<Td
			stopPropagation
			property="defaultAnalysis[name]"
		>
			<Select
				showLabel={false}
				emptyText="N/A"
				value={row.defaultAnalysis?.id ?? null}
				options={analyses}
				disabled={!$canEditTypeStore[row.uuid]}
				on:change={e => onObjPropChange(row, 'defaultAnalysis', intTargetValueOrNull(e), analyses)}
				let:option
			>
				<option value={option?.id ?? null}>{option?.name}</option>
			</Select>
		</Td>
		<Td
			stopPropagation
			property="defaultGroup[name]"
		>
			<Select
				showLabel={false}
				emptyText="N/A"
				value={row.defaultGroup?.id ?? null}
				options={groups}
				disabled={!$canEditTypeStore[row.uuid]}
				on:change={e => onObjPropChange(row, 'defaultGroup', intTargetValueOrNull(e))}
				let:option
			>
				<option value={option?.id ?? null}>{option?.name}</option>
			</Select>
		</Td>
		<Td
			stopPropagation
			property="visibleGroup[name]"
		>
			<Select
				showLabel={false}
				emptyText="All Groups"
				value={row.visibleGroup?.id ?? null}
				options={groups}
				disabled={!$canEditTypeStore[row.uuid]}
				on:change={e => onObjPropChange(row, 'visibleGroup', intTargetValueOrNull(e))}
				let:option
			>
				<option value={option?.id ?? null}>{option?.name}</option>
			</Select>
		</Td>
		<Td
			stopPropagation
			property="showRecipes"
		>
			<input
				type="checkbox"
				checked={row.showRecipes}
				disabled={!$canEditTypeStore[row.uuid]}
				on:change={e => onChange(row, 'showRecipes', getEventChecked(e))}
			/>
		</Td>
		<Td
			stopPropagation
			property="showTasks"
		>
			<input
				type="checkbox"
				checked={row.showTasks}
				disabled
			/>
		</Td>
		<Td
			stopPropagation
			property="titleRequired"
		>
			<input
				type="checkbox"
				checked={row.titleRequired}
				disabled={!$canEditTypeStore[row.uuid]}
				on:change={e => onChange(row, 'titleRequired', getEventChecked(e))}
			/>
		</Td>
		<Td
			stopPropagation
			property="productBatchRequired"
		>
			<Select
				showLabel={false}
				showEmptyOption={false}
				value={row.showProduct === 'NONE' ? 'HIDE' : row.productBatchRequired}
				disabled={row.showProduct === 'NONE' || !$canEditTypeStore[row.uuid]}
				on:change={e => isProductBatchOption(getEventValue(e)) && onChange(row, 'productBatchRequired', getEventValueEnum(e, 'SHOW', 'HIDE', 'REQUIRE'))}
			>
				<option value="SHOW">{translate('woTypes.productBatch.show', 'Show')}</option>
				<option value="HIDE">{translate('woTypes.productBatch.hide', 'Hide')}</option>
				<option value="REQUIRE">{translate('woTypes.productBatch.require', 'Require')}</option>
			</Select>
		</Td>
	</tr>
</Table>

<div class="card-footer mx-n3 border-bottom">
	<Button
		outline
		size="sm"
		color="success"
		iconClass="plus"
		on:click={createWoType}>{translate('woTypes.newWoTypeLabel', 'New Work Order Type')}</Button
	>
	<Button
		outline
		size="sm"
		color="danger"
		iconClass="trash"
		disabled={!selectedWoType || !$canEditTypeStore[selectedWoType.uuid]}
		on:click={onDelete}>{translate('woTypes.deleteLabel', 'Delete')}...</Button
	>
</div>
