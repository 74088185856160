export default {
    "name": "AnalysisOptionChoicesForSpecifications",
    "kind": "HoudiniQuery",
    "hash": "15d21e7a6cda19e405756088fe1e8835d6b770378d43ac159a7c6d2b52dae811",

    "raw": `query AnalysisOptionChoicesForSpecifications($filter: AnalysisOptionChoiceFilter) {
  analysisOptionChoices(filter: $filter) {
    data {
      id
      choice
    }
  }
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "analysisOptionChoices": {
                "type": "AnalysisOptionChoiceResponse",
                "keyRaw": "analysisOptionChoices(filter: $filter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "AnalysisOptionChoice",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "choice": {
                                        "type": "String",
                                        "keyRaw": "choice",
                                        "visible": true
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {
            "isManualLoad": true
        }
    },

    "input": {
        "fields": {
            "filter": "AnalysisOptionChoiceFilter"
        },

        "types": {
            "AnalysisOptionChoiceFilter": {
                "active": "Boolean",
                "hasProductBatch": "Boolean",
                "optionId": "PositiveInt",
                "optionName": "String",
                "plantId": "PositiveInt",
                "productBatchIds": "PositiveInt",
                "productIds": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=0fa96d8e0d28b7445aeb2c4adc6f3d686e352446c01abc6f59c6151ff3148b58";