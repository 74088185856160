export default {
    "name": "AmAnalysisOptionChoices",
    "kind": "HoudiniQuery",
    "hash": "1303616daeff5a2b229413d2b5b901cc9765b8a462db6715bd9f071e21bfbd40",

    "raw": `query AmAnalysisOptionChoices($filter: AnalysisOptionChoiceFilter) {
  analysisOptionChoices(filter: $filter) {
    data {
      ...AnalysisManagementChoiceData
      id
    }
  }
}

fragment AnalysisManagementChoiceData on AnalysisOptionChoice {
  active
  analysisOptionId
  plantId
  constraint
  boundaryType
  choice
  id
  product {
    name
    id
  }
  severityClass {
    id
    name
  }
  __typename
}
`,

    "rootType": "Query",
    "stripVariables": [],

    "selection": {
        "fields": {
            "analysisOptionChoices": {
                "type": "AnalysisOptionChoiceResponse",
                "keyRaw": "analysisOptionChoices(filter: $filter)",

                "selection": {
                    "fields": {
                        "data": {
                            "type": "AnalysisOptionChoice",
                            "keyRaw": "data",

                            "selection": {
                                "fields": {
                                    "active": {
                                        "type": "Boolean",
                                        "keyRaw": "active",
                                        "visible": true
                                    },

                                    "analysisOptionId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "analysisOptionId",
                                        "visible": true
                                    },

                                    "plantId": {
                                        "type": "PositiveInt",
                                        "keyRaw": "plantId",
                                        "nullable": true,
                                        "visible": true
                                    },

                                    "constraint": {
                                        "type": "ConstraintType",
                                        "keyRaw": "constraint",
                                        "visible": true
                                    },

                                    "boundaryType": {
                                        "type": "BoundaryType",
                                        "keyRaw": "boundaryType",
                                        "visible": true
                                    },

                                    "choice": {
                                        "type": "String",
                                        "keyRaw": "choice",
                                        "visible": true
                                    },

                                    "id": {
                                        "type": "PositiveInt",
                                        "keyRaw": "id",
                                        "visible": true
                                    },

                                    "product": {
                                        "type": "Product",
                                        "keyRaw": "product",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                },

                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "severityClass": {
                                        "type": "SeverityClass",
                                        "keyRaw": "severityClass",
                                        "nullable": true,

                                        "selection": {
                                            "fields": {
                                                "id": {
                                                    "type": "PositiveInt",
                                                    "keyRaw": "id",
                                                    "visible": true
                                                },

                                                "name": {
                                                    "type": "String",
                                                    "keyRaw": "name",
                                                    "visible": true
                                                }
                                            }
                                        },

                                        "visible": true
                                    },

                                    "__typename": {
                                        "type": "String",
                                        "keyRaw": "__typename",
                                        "visible": true
                                    }
                                },

                                "fragments": {
                                    "AnalysisManagementChoiceData": {
                                        "arguments": {}
                                    }
                                }
                            },

                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "filter": "AnalysisOptionChoiceFilter"
        },

        "types": {
            "AnalysisOptionChoiceFilter": {
                "active": "Boolean",
                "hasProductBatch": "Boolean",
                "optionId": "PositiveInt",
                "optionName": "String",
                "plantId": "PositiveInt",
                "productBatchIds": "PositiveInt",
                "productIds": "PositiveInt"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    },

    "policy": "NetworkOnly",
    "partial": false
};

"HoudiniHash=f9f73294e181663f877d6abb64286fceae5cc455647c6ca0756d3d0b37b67005";