<script lang="ts">
	import type { i18n, Mediator } from 'types/common'
	import type { Report, ReportJob } from '@isoftdata/svelte-report-job-modal'

	import ReportJobModal from '@isoftdata/svelte-report-job-modal'
	import { getContext, onMount, type ComponentProps } from 'svelte'
	import { graphql } from '$houdini'
	import session from 'stores/session'
	import { GenerateCrystalReportStore } from '$houdini/index'
	import type { FavoriteReportsStore } from 'stores/favorite-reports'
	import makeFavoriteReportsStore from 'stores/favorite-reports'
	import loadReports from 'utility/report-viewer-helper'

	export let showPreview = true
	export let plantId = $session.siteId

	const { t: translate } = getContext<i18n>('i18next')
	const mediator = getContext<Mediator>('mediator')

	type OpenFn = Exclude<ComponentProps<ReportJobModal>['open'], undefined>
	type Params = Parameters<OpenFn>

	let internalModal: ReportJobModal
	let showPrint = false
	let favoriteReports: FavoriteReportsStore | undefined = undefined
	let favoriteReportNames: Array<string> = []
	// dumb hack because I have to init the favorite store before I can set the value
	$: favoriteReports?.set(favoriteReportNames)

	export async function open(reportJob: Params[0], options?: Params[1]) {
		const contextEmails = options?.emails?.filter(e => e) ?? []

		await internalModal.open(reportJob, {
			...options,
			emails: contextEmails.length ? contextEmails : await loadSuggestedEmails(),
		})
	}

	async function loadSuggestedEmails() {
		const { data } = await getEmailSuggestions.fetch()
		return data?.getEmailSuggestions ?? []
	}

	async function loadPrinters(reportType: string) {
		const { data } = await getPrinters.fetch({
			variables: {
				filter: {
					reportTypes: [reportType],
					plantIds: [plantId],
				},
			},
		})

		showPrint = !!data?.printers?.data?.length

		return data?.printers?.data ?? []
	}

	async function loadDefaultPrinter(reportType: string): Promise<string> {
		const { data } = await getDefaultReportPrinter.fetch({
			variables: {
				input: {
					reportType,
				},
			},
		})
		return data?.getDefaultReportPrinter?.printer ?? ''
	}

	async function generatePdfPreview({ name, type, parameters, id }: Parameters<ComponentProps<ReportJobModal>['generatePdfPreview']>[0]): Promise<{ data: string; mimeType: string }> {
		const { data } = await generateCrystalReport.mutate({
			reportJob: {
				name,
				parameters: Object.entries(parameters).map(([key, value]) => ({ key, value })),
				type,
				id,
			},
		})
		if (!data) {
			throw new Error(translate('workOrder.generateReportNoDataError', 'No data returned from generateCrystalReport'))
		}
		return data.generateCrystalReport
	}

	async function saveReportJobs(reportJobs: ReportJob[]): Promise<void> {
		try {
			await Promise.all(
				reportJobs.map(({ parameters, ...reportJob }) =>
					createReportQueueJob.mutate({
						newReportQueue: {
							...reportJob,
							parameters: Object.entries(parameters).map(([parameterName, value]) => ({ parameterName, value })),
						},
					}),
				),
			)
		} catch (err: any) {
			console.error(err)
			mediator.call('showMessage', {
				heading: translate('workOrder.saveReportJobsError', 'Error saving report jobs'),
				message: err.message,
				type: 'danger',
				time: false,
			})
		}
	}

	async function savePrinterPreference(printer: string, reportType: string): Promise<void> {
		try {
			await setDefaultReportPrinter.mutate({
				input: {
					reportType,
					printer,
					plantId,
					userId: $session.userAccountId,
				},
			})
		} catch (err) {
			console.error("An error occurred while saving the user's printer preference", err)
		}
	}

	const getDefaultReportPrinter = graphql(`
		query GetDefaultReportPrinter($input: GetReportPrinter!) {
			getDefaultReportPrinter(input: $input) {
				printer
			}
		}
	`)

	const setDefaultReportPrinter = graphql(`
		mutation SetDefaultReportPrinter($input: SetReportPrinter!) {
			setDefaultReportPrinter(input: $input) {
				id
			}
		}
	`)

	const createReportQueueJob = graphql(`
		mutation CreateReportQueueJob($newReportQueue: NewReportQueue!) {
			createReportQueueJob(newReportQueue: $newReportQueue) {
				id
			}
		}
	`)

	const getPrinters = graphql(`
		query GetPrinters($filter: ReportQueuePrinterFilter) {
			printers: reportQueuePrinters(filter: $filter, pagination: { pageSize: 0 }) {
				data {
					dateLastSeen
					displayName
					hidden
					id
					name
					online
					plantId
				}
			}
		}
	`)

	const getReports = graphql(`
		query GetReports($reportsFilter: ReportsFilter) {
			reports(filter: $reportsFilter, pagination: { pageSize: 0 }) {
				data {
					id
					name
					type
				}
			}
		}
	`)

	const generateCrystalReport = new GenerateCrystalReportStore()

	const getEmailSuggestions = graphql(`
		query GetEmailSuggestions {
			getEmailSuggestions
		}
	`)

	const getFavoriteReports = graphql(`
		query GetFavoriteReports {
			session {
				user {
					favoriteReports: getUserSetting(lookup: { category: "Report Viewer", settingType: INTERFACE_HISTORY, name: "Favorite reports", defaultValue: "" }) {
						value
					}
				}
			}
		}
	`)

	onMount(async () => {
		const { data } = await getFavoriteReports.fetch()
		favoriteReports = makeFavoriteReportsStore(data?.session?.user?.favoriteReports?.value)
		favoriteReportNames = $favoriteReports ?? []
	})
</script>

<!-- @component To use this component, you can simply call its `open` function

	```svelte
	<script lang="ts">
		import ReportJobModal from 'components/ReportJobModal.svelte'
		let reportJobModal: ReportJobModal | undefined = undefined
		reportJobModal.open(
			{
				// print job details
				type: 'Work Order',
				parameters: { workorderid: workOrder.id.toString() },
			},
			{
				// other options
			},
		)
	</script>
	<ReportJobModal bind:this={reportJobModal}></ReportJobModal>
	```
-->

<ReportJobModal
	{showPrint}
	{showPreview}
	loadReports={reportType =>
		loadReports(
			{
				filter: {
					type: reportType ? [reportType] : undefined,
				},
			},
			translate,
		)}
	{loadPrinters}
	{loadDefaultPrinter}
	{generatePdfPreview}
	{saveReportJobs}
	{savePrinterPreference}
	bind:favoriteReportNames
	bind:this={internalModal}
></ReportJobModal>
