import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/WorkOrderChangelog'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class WorkOrderChangelogStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "WorkOrderChangelogStore",
			variables: true,
		})
	}
}

export async function load_WorkOrderChangelog(params) {
	await initClient()

	const store = new WorkOrderChangelogStore()

	await store.fetch(params)

	return {
		WorkOrderChangelog: store,
	}
}
