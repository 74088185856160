import { QueryStore } from '../runtime/stores/query'
import artifact from '$houdini/artifacts/ProductBatchesLocations'
import { initClient } from '$houdini/plugins/houdini-svelte/runtime/client'

export class ProductBatchesLocationsStore extends QueryStore {
	constructor() {
		super({
			artifact,
			storeName: "ProductBatchesLocationsStore",
			variables: false,
		})
	}
}

export async function load_ProductBatchesLocations(params) {
	await initClient()

	const store = new ProductBatchesLocationsStore()

	await store.fetch(params)

	return {
		ProductBatchesLocations: store,
	}
}
