export default {
    "name": "UpdateProductBatches",
    "kind": "HoudiniMutation",
    "hash": "165565312f2795c237e8329e256e9b19b47113a6e53c728ac3335dd4c0220c5b",

    "raw": `mutation UpdateProductBatches($productBatches: [UpdateProductBatch!]!) {
  updateProductBatches(productBatches: $productBatches) {
    id
  }
}
`,

    "rootType": "Mutation",
    "stripVariables": [],

    "selection": {
        "fields": {
            "updateProductBatches": {
                "type": "ProductBatch",
                "keyRaw": "updateProductBatches(productBatches: $productBatches)",
                "nullable": true,

                "selection": {
                    "fields": {
                        "id": {
                            "type": "PositiveInt",
                            "keyRaw": "id",
                            "visible": true
                        }
                    }
                },

                "visible": true
            }
        }
    },

    "pluginData": {
        "houdini-svelte": {}
    },

    "input": {
        "fields": {
            "productBatches": "UpdateProductBatch"
        },

        "types": {
            "UpdateProductBatch": {
                "description": "String",
                "end": "DateTime",
                "id": "PositiveInt",
                "locationId": "PositiveInt",
                "name": "String",
                "plantId": "PositiveInt",
                "productId": "PositiveInt",
                "start": "DateTime",
                "status": "ProductBatchStatus"
            }
        },

        "defaults": {},
        "runtimeScalars": {}
    }
};

"HoudiniHash=3bc55789fc0cf7a16af839b17f4dd22d846c9537da8e91322e2754e9d0ea8e90";